(function ($) {
    $(function () {
        const aside = document.getElementById("blog-toc");
        if (aside) {
            // https://www.freecodecamp.org/news/how-to-make-a-dynamic-table-of-contents-in-javascript/
            const headings = Array.from(document.querySelectorAll("#blog-content h2.red, #blog-content h3.red"));
            const ul = document.createElement("ul");
            aside.appendChild(ul);
            headings.map((heading, index) => {
                const id = "heading-" + index;
                heading.setAttribute("id", id);
                const anchorElement = `<a href="#${id}">${heading.textContent}</a>`;
                const keyPointer = `<li>${anchorElement}</li>`;
                ul.insertAdjacentHTML("beforeend", keyPointer);
            });
            const tocAnchors = aside.querySelectorAll("a");
            tocAnchors.forEach((link) => {
                link.addEventListener('click', function (e) {
                    e.preventDefault();
                    // make sure we add the active class, in case the interaction observer doesnt kick in
                    tocAnchors.forEach((tab) => {
                        tab.classList.remove("active");
                    });
                    link.classList.add("active");
                    // scroll to target
                    let target = document.querySelector(this.hash);
                    target.scrollIntoView({
                        behavior: 'smooth',
                        alignToTop: true,
                        block: 'start'
                    });

                });
            });
            const obFunc = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = headings.indexOf(entry.target);
                        tocAnchors.forEach((tab) => {
                            tab.classList.remove("active");
                        });
                        tocAnchors[index].classList.add("active");
                    }
                });
            };
            const obOption = {
                rootMargin: "-30px 0% -77%",
                threshold: 1
            };
            const observer = new IntersectionObserver(obFunc, obOption);
            headings.forEach((hTwo) => observer.observe(hTwo));
        }
    });
})(jQuery);